<template>
  <router-view></router-view>
</template>


<script>
export default {
  name: "group-general",
  data: () => ({}),
};
</script>

<style>
</style>